import React, { useState } from 'react';
import './create-transaction-link.css';
import DashboardNavbar from '../../../components/Dashboard Navbar/DashboardNavbar';
import { useNavigate } from 'react-router-dom';
import backArrow from '../../../Images/backIcon.svg';
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const CreateTransactionLink = () => {

  const [name, setName] = useState()
  const [amount, setAmount] = useState()
  const [options, setOptions] = useState([])
  const [mode, setMode] = useState('Custom')
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const create_link = () => {
    setLoading(true)
    let data = {
      name,
      amount,
      options,
      mode,
      user_id: localStorage.getItem("id")
    }

    axios.post('/payments/create-link', data)
      .then(res => {
        setLoading(false)
        navigate('/dashboard/transaction-links')
      })
      .catch(err => {
        setLoading(false)
      })

  }

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <section className="dashboard-overview-container create-transaction-container">
      <DashboardNavbar />

      <header>
        <div className="back-btn" onClick={() => navigate("/dashboard/transaction-links")}>
          <img src={backArrow} alt="" />

          <p>Transaction List</p>
        </div>

        <span className="create-transaction-cta">
          {/* <button className="save-draft-btn">Save As Draft</button> */}

          <button className="create-link-btn" onClick={() => create_link()}>Create Link</button>
        </span>
      </header>

      <main>
        <h4>Create New Transaction Link</h4>

        <form action="">
          <label htmlFor="">Transaction Name</label>
          <input type="text" placeholder="Enter Transaction Name" value={name} onChange={ev => setName(ev.target.value)} />

          <label htmlFor="">Choose Amount Mode</label>
          {/* <input type="text" /> */}

          <div className="payment-options">

            <span>
              <input type="radio" name='mode' value="Custom" checked={mode === "Custom" ? true : false} onChange={(ev) => setMode(ev.target.value)} />
              <p>Custom (Your customers set amount)</p>
            </span>

            <span>
              <input type="radio" name='mode' value="Predefined" onChange={(ev) => setMode(ev.target.value)} />
              <p>Predefined (You set amount)</p>
            </span>

          </div>

          {mode === "Predefined" && <label htmlFor="">Transaction Amount(NGN)</label>}
          {mode === "Predefined" && <input type="number" placeholder="Enter Transaction Amount" onChange={(ev) => setAmount(ev.target.value)} />}

          <label htmlFor="">Funding Option (choose one or more)</label>

          <div className="payment-options">


            <span>
              <input type="checkbox" name='options' value='card' onChange={ev => setOptions(options.includes(ev.target.value) ? options.filter(op => op !== ev.target.value) : [...options, ev.target.value])} />
              <p>Pay With Card</p>
            </span>
            <span>
              <input type="checkbox" name='options' value='ussd' onChange={ev => setOptions(options.includes(ev.target.value) ? options.filter(op => op !== ev.target.value) : [...options, ev.target.value])} />
              <p>Pay With USSD</p>
            </span>
            <span>
              <input type="checkbox" name='options' value='transfer' onChange={ev => setOptions(options.includes(ev.target.value) ? options.filter(op => op !== ev.target.value) : [...options, ev.target.value])} />
              <p>Pay With Bank Transfer</p>
            </span>

            <span>
              <input type="checkbox" name='options' value='qrcode' onChange={ev => setOptions(options.includes(ev.target.value) ? options.filter(op => op !== ev.target.value) : [...options, ev.target.value])} />
              <p>Pay With QR Code</p>
            </span>

            <span>
              <input type="checkbox" name='options' value='mobile_money' onChange={ev => setOptions(options.includes(ev.target.value) ? options.filter(op => op !== ev.target.value) : [...options, ev.target.value])} />
              <p>Pay With Mobile Money</p>
            </span>

            <span>
              <input type="checkbox" name='options' value='mpesa' onChange={ev => setOptions(options.includes(ev.target.value) ? options.filter(op => op !== ev.target.value) : [...options, ev.target.value])} />
              <p>Pay With Mpesa</p>
            </span>

          </div>

        </form>
      </main>
    </section>
  );
};

export default CreateTransactionLink;
