import React from 'react';

import Navbar from '../../components/Navbar/Navbar';
import './heroe-container.css';
// import { Link } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';

function Privacy() {
    return (
        <>
            <div className="heroe-container">
                <Navbar />

                <div style={{
                    marginTop: 100
                }}>
                    <h1>PRIVACY POLICY</h1>

                    <h2>INTRODUCTION</h2>

                    At PAYSURE TECHNOLOGIES LIMITED (“Paysure”, “we” “our” “us” or “Company”), we are deeply committed to protecting the privacy and security of our users' personal and financial information. We understand that the trust our users place in us to handle their data is of the utmost importance, and we take this responsibility very seriously. This Data Privacy and Protection Policy (the “Privacy Policy”) describes your privacy rights regarding the Company’s collection, use, storage, sharing and protection of your personal identifiers, electronic network activity information, professional information, location information and other types of information. It applies to our platform, website and all related sites, applications, services and tools (“Services”) regardless of how you access or use them.

                    This privacy policy outlines the types of information we collect in relation to your use of our website, product, and services, how we collect and use it, and the steps we take to safeguard the information we obtain from you. We are dedicated to providing a secure and reliable payment processing service that you can trust.

                    Our privacy policy may be reviewed from time to time either as a result of government regulation, new technologies, or other developments in the data protection laws regime generally. Kindly visit the Paysure’s website periodically to read and accept the changes to continue your use of the website or the services.

                    To use our Services, you will voluntarily provide us with certain Personal Information. Personal Information refers to information relating to an identified person or information that can be used to identify you. It may also include anonymous information that may be linked to you specifically, (e.g. IP address).

                    <h3>1.	DEFINITION</h3>
                    “Cookies” means a small data file that is transferred to your computer or mobile device. It enables us to remember your account log-in information, IP addresses, web traffic number of times you visit, browser type and version, device details, and date and time of visits.

                    “Personal Data” means any personal information collected for you in relation to your use of this service that is capable of identifying you.

                    “Services” means the services offered on the Website.

                    “Third Parties” means a natural or legal person, public authority, agency, or body other than the data subject, controller, processor, and persons who, under the direct authority of the controller or processor, are authorized to process personal data.

                    “Website” means our website, which is known as www.Paysurehub.com including all pages, sub-pages, all blogs, forums, and other connected internet content whatsoever.

                    “You, Yours” means the user of this Website.

                    <h3>2.	THE INFORMATION WE COLLECT FROM YOU</h3>

                    Business Information

                    As part of our operations, we collect and process certain types of information of individuals that makes them easily identifiable. These individuals include current, past and prospective employees, merchants, suppliers/vendors, customers of merchants, registered users (“Users”) and other individuals whom we communicate or deal with, jointly and/or severally (“Data Subject(s)”).

                    To use our Services, you will voluntarily provide us with certain Personal Information. Personal Information refers to information relating to an identified person or information that can be used to identify you. It may also include anonymous information that may be linked to you specifically, (e.g. IP address).

                    We may collect the following information from you:
                    Identity Data: This includes your business name, business incorporation information, management information, or any other similar identifier.

                    Contact Data: This includes billing address, delivery address, email address, and telephone numbers.

                    Financial Data: This includes bank account and payment card details.

                    Transaction Data: This includes details about payments to and from you, transaction time/length, and merchant details.

                    Profile Data: This includes orders you make, interests, preferences, feedback, and survey responses.

                    Usage Data: This includes information about how you use our website, products, and services.

                    Take note that we may collect, use, store, and transfer the different kinds of data you supply to us in accordance with our “know your client” obligations under the Central Bank of Nigeria (Anti-Money Laundering and Combating the Financing of Terrorism (AML/CFT) in Banks and Other Financial Regulations (Amendment) Regulations, 2019.

                    We do not collect any other special categories of personal data about you or your representatives (this includes details about your health, race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, and genetic data). Nor do we collect any information about criminal convictions and offenses.
                    <h3>3.	THE INFORMATION WE COLLECT FROM YOU AUTOMATICALLY</h3>

                    Technical Data: This includes but is not limited to your IP address, browser type, mobile device ID, device type, operating system version, connection information, mobile network information, a location derived from GPS-enabled services, and information based on your usage of the service such as time, date and duration of your use, referral URLs, search terms and search history, camera, contact list, browsing history, purchase history, advertisement interactions, and other statistics, including transactions you carry out when using our service.

                    Session Cookies: Our website uses session cookies to identify you as a user and make your user experience easier, customize our services, content, and advertising; help you ensure that your account security is not compromised, mitigate risk and prevent fraud; and promote trust and safety on our website. Cookies allow our servers to remember IP addresses, date and time of visits, monitor web traffic and prevent fraudulent activities.

                    Our cookies never store personal or sensitive information; they simply hold a unique random reference to you so that once you visit our website we can recognize who you are and provide certain content to you.

                    If your browser or browser add-on permits, you have the choice to disable cookies on our website, however, this may impact your experience using our website

                    <h3>4.	INFORMATION WE OBTAIN FROM THIRD PARTIES</h3>
                    We may retrieve additional personal information about you from third parties and other identification/verification services such as your financial institution and payment processor.

                    <h3>4.1	DISCLOSURE OF INFORMATION TO THIRD PARTIES</h3>
                    We will not, however, share or disclose your personal information with a third party without your consent except as may be required for the purpose of providing you with our services or under applicable legislation. Hence, we do not sell, trade, or otherwise transfer your personally identifiable information to third parties. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.

                    You, therefore, agree that we have the right to share your personal information:
                    1. with any of our affiliated companies, subsidiaries, website providers, and professional advisers on a need-to-know basis;

                    2. with financial website providers, including the financial institutions identified in your cardholder bank agreement that provide banking Websites in connection with your account;

                    3. with non-financial companies, such as email website providers that perform marketing websites on our behalf, and fraud prevention website providers that use the information to provide websites to us;

                    4. with a non-affiliated third party to access and transmit your personal and financial information from a relevant financial institution. You grant the third party the right, power, and authority to access and transmit this information according to the terms of their privacy policy;

                    5. with selected third parties including business partners, suppliers, and sub-contractors for the performance of any contract we enter into with them or you;

                    6. with third parties to whom we may choose to sell, transfer or merge parts of our business or our assets or alternatively, acquire all or parts of their businesses.

                    <h3>4.2	TRANSFER OF PERSONAL INFORMATION</h3>
                    i.	Third Party Processor within Nigeria

                    We may engage the services of third parties in order to process the Personal Information of Data Subjects we collect. The processing by such third parties shall be governed by a written contract with us to ensure adequate protection and security measures are put in place by the third party for the protection of Personal Information in accordance with the terms of this Privacy Policy.

                    We may share your information with law enforcement agencies, public or tax authorities or other organizations if legally required to do so, or if we have a good faith belief that such use is reasonably necessary to:
                    a.	comply with a legal obligation, process or request (including tax and related reporting requirements);
                    b.	enforce our Terms of Service and other agreements, policies, and standards, including investigation of any potential violation thereof;
                    c.	detect, prevent or otherwise address security, fraud or technical issues; or
                    d.	protect the rights, property or safety of us, our users, a third party or the public as required or permitted by law (including exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction).

                    ii.	Transfer of Personal Information to Foreign Country

                    We may rely on third-party servers located in foreign jurisdictions from time to time, which as a result, may require the transfer or maintenance of your personally identifiable information on computers or servers in foreign jurisdictions. We will endeavor to ensure that such foreign jurisdictions have data protection legislation that is no less than the existing data protection regulations in force in Nigeria and your personally identifiable information is treated in a safe and secure manner.

                    Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.

                    We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies or protect our or others’ rights, property, or safety. Hence, we may disclose information:
                    ●	in response to a request for information, if we are required by, or we believe disclosure is in accordance with, any applicable law, regulation, or legal process; and with relevant law enforcement officials or other third parties, such as investigators or auditors, if we believe it is appropriate to investigate fraud.
                    ●	where It is necessary to establish, make or defend legal claims.
                    ●	where It is necessary to prevent or detect an unlawful act in circumstances where we must carry out checks without your permission so as not to affect the outcome of those checks.
                    ●	where It is necessary for a purpose designed to protect the public against dishonesty, malpractice, or other seriously improper behavior.
                    ●	where It is in the public interest, in line with any laws that apply.
                    ●	where It is information that you have made public, or we have your permission.


                    Where Personal Information is to be transferred to a country outside Nigeria, we shall put adequate measures in place to ensure the security of such Personal Information. In particular, we shall, among other things, confirm whether the country is on the National Information Technology Development Agency (“NITDA”) Whitelist of Countries with adequate data protection laws.

                    Transfer of Personal Information out of Nigeria would be in accordance with the provisions of the Nigeria Data Protection Regulation 2019 ("NDPR”). We will therefore only transfer Personal Information out of Nigeria on one of the following conditions:
                    a.	The consent of the Data Subject has been obtained;
                    b.	the transfer is necessary for the performance of a contract between us and the Data Subject or implementation of pre-contractual measures taken at the Data Subject’s request;
                    c.	the transfer is necessary to conclude a contract between us and a third party in the interest of the Data Subject;
                    d.	the transfer is necessary for reason of public interest;
                    e.	the transfer is for the establishment, exercise or defense of legal claims;
                    f.	The transfer is necessary in order to protect the vital interests of the Data Subjects or other persons, where the Data Subject is physically or legally incapable of giving consent.

                    <h3>4.3 THIRD-PARTY LINKS</h3>
                    Our website, products, and services may contain links to other websites not subject to this Privacy Policy. They may collect your personal information in accordance with their privacy and data collection policies when you click on those links or enable those connections. We are not responsible for the data collected by these third parties and you are advised to study their privacy policies before you make use of their services.

                    <h3>5.	 HOW WE COLLECT YOUR INFORMATION</h3>
                    We collect the information you consent to give us about you by filling in forms on our Site, or by corresponding with us (for example, by email or chat). It includes information you provide when you register or sign up for our Services, and when reporting a problem with our Site or our services.

                    <h3>6.	HOW YOUR INFORMATION IS PROCESSED</h3>
                    We process your personal information for the purposes set out in this privacy policy. We
                    process personal information if necessary to provide the services set out in a contract, if it is in our or a third party’s legitimate interests, or if it is required or allowed by any law that applies.

                    We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
                    ●	Where you have consented before the processing.
                    ●	Where we need to perform the services for you.
                    ●	Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.
                    ●	Where we need to comply with a legal or regulatory obligation.

                    GOVERNING PRINCIPLES OF DATA PROCESSING
                    We process your information in accordance with the provisions of the Nigeria Data Protection Regulation 2019 and all other applicable legislation relating to privacy or data protection in Nigeria (the Regulations). The principles are that:

                    1. Your personal data is collected and processed in accordance with specific, legitimate, and lawful purposes consented to by you, provided that further processing may be done by archiving the data for public interest, scientific or research purposes, or statistical purposes.

                    2. The data collected is adequate, accurate, and without prejudice to the dignity of the human person.

                    3. The data collected is stored only for the period within which it is reasonably needed.

                    4. The data collected is secured against all foreseeable hazards and breaches such as theft, cyberattack, viral attack, dissemination, manipulations of any kind, and damage by rain, fire, or exposure to other natural elements.

                    5. We owe a duty of care to you in respect of the data we have obtained from you.

                    <h3>7.	WHAT WE USE YOUR INFORMATION FOR</h3>
                    Any information we collect from you may be used in one of the following ways:

                    1.	To personalize your experience (your information helps us to better respond to your personal needs).
                    2.	To provide access to, maintain, and improve the Services.
                    3.	To provide and deliver the products and services requested, process transactions and send you transaction-related information, verify your identity, and prevent fraudulent transactions.
                    4.	To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you).
                    5.	To send you technical notices, updates, security alerts, support, and administrative notifications.
                    6.	To provide customer service; We process your personal information when you contact us to resolve any questions, or disputes, collect fees or troubleshoot problems. Without processing your personal information for such purposes, we cannot respond to your requests and ensure your uninterrupted use of the Services.
                    7.	To send periodic emails. The email address you provide may send you information about our Plans and services and receive occasional company news, updates, related product or service information, etc. However, we will provide you with an option to unsubscribe if you do not want to hear from us;
                    8.	To ensure network and information security; We process your personal information in order to enhance security, monitor and verify identity or service access, combat other malware or security risks, and comply with applicable security laws and regulations in our jurisdiction.
                    9.	To administer a contest, promotion, survey, rewards, events, or other site features.
                    10.	For any purpose that you provide consent; We will not use your personal information for purposes other than those purposes we have disclosed to you, without your permission. From time to time, and as required under the applicable data protection and privacy law, we may request your permission to allow us to share your personal information with third parties. In such instances, you may opt out of having your personal information shared with third parties, or allowing us to use your personal information for any purpose that is incompatible with the purposes for which we originally collected it or subsequently obtained your authorization. If you choose to limit the use of your personal information, certain features or Services may not be available to you.

                    <h3>9. DATA RETENTION PERIOD</h3>
                    We will retain your information for as long as is needed to provide our services to you, comply with our legal and statutory obligations, or verify your information with a financial institution.

                    Paysure is statutorily obligated to retain the data you provide us with in order to process transactions, ensure settlements, make refunds, identify fraud, and be in compliance with laws and regulatory guidelines applicable to us, our banking providers, and card processors. We may, therefore, retain certain personal information and transaction data to comply with these obligations. All personal information shall be destroyed by Paysure where possible. For all personal data and records obtained, used, and stored by Paysure we shall perform periodical reviews of the data retained to confirm the accuracy, purpose, validity, and requirement to retain.



                    The length of storage of personal information shall, amongst other things, be determined by:
                    1. the contract terms agreed between Paysure and the data subject or as long as it is needed for the purpose for which it was obtained; or
                    2. whether the transaction or relationship has statutory implication or a required retention period; or
                    3. whether there is an express request for deletion of personal data by the data subject, provided that such request will only be treated where the data subject is not under any investigation which may require Paysure to retain such personal data or there is no subsisting contractual arrangement with the data subject that would require the processing of the personal data; or
                    4. whether Paysure has another lawful basis for retaining that information beyond the period for which it is necessary to serve the original purpose.


                    <h3>10. HOW WE SAFEGUARD YOUR INFORMATION</h3>
                    The security of your Personal Information is important to the Company. We maintain appropriate Internal, technical, and physical controls to ensure that your data are kept safe. HTTPS will be put in place for the transmission of the data. Other security measures include but are not limited to, Two-factor authentication (2FA),  secure servers, firewalls, data encryption, and granting access only to designated employees.

                    In addition to the security measures we employ, you must also ensure that you take responsibility for the protection of your personal information. We will not be liable for any loss or damage caused by the activities of hackers and fraudsters when we have taken all necessary and reasonable steps to protect your information in our possession. You are also responsible for safeguarding your username, password, and financial information.

                    <h3>11.	DATA MINIMIZATION</h3>
                    We limit Personal Information collection and usage to data that is relevant, adequate, and necessary for carrying out the purpose for which the data is processed. We will evaluate whether and to what extent the processing of Personal Information is necessary and where the purpose allows, anonymized data will be used.

                    <h3>12. International Data Transfers</h3>
                    Where Personal Information is to be transferred to a country outside Nigeria, Paysure shall put adequate measures in place to ensure the security of such Personal Information and to ensure same is done securely and in accordance with the Nigerian Data Protection Regulation.

                    <h3>13.	YOUR PRIVACY RIGHTS</h3>
                    By providing us with your personal information, you have certain rights under data protection regulations about your personal data. These rights are not absolute, nonetheless, they include:
                    1. the right to request access to your personal data in our possession;

                    2. the right to object to or request the restriction of the processing of your personal data;

                    3. the right to request rectification and modification of personal information which Klasha keeps;

                    4. the right to request the deletion of your personal data in our possession;

                    5. The right to request the transfer of your personal data to you or to a third party.

                    6. The right to withdraw your consent at any time when we are relying on consent to process your personal data.

                    If you wish to exercise any of the rights set out above, please contact us through the details provided below. All such requests will be reviewed and considered in accordance with the provisions of the applicable data protection regulations.

                    You will not have to pay a fee to access your personal data (or exercise any other rights). However, we may charge a reasonable fee if your request is unfounded, repetitive, or excessive. We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask for further information about your request to speed up our response.

                    We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made several requests. In this case, we will notify you and keep you updated.

                    <h3>14. Data Protection Audit</h3>
                    Paysure  shall conduct an annual data protection audit through a licensed Data Protection Compliance Organization (DPCOs) to verify Paysure’s compliance with the provisions of the NDPR and other applicable data protection laws.
                    The audit report will be certified and filed by the DPCO to the National Information Technology Development Agency (NITDA) as required under the NDPR.


                    <h3>15.	CONTACT DETAILS</h3>
                    All comments, questions, access requests, complaints, and other requests regarding the privacy policy should be sent to _____________________

                    We maintain a data breach procedure in order to deal with incidents concerning personal information or practices leading to the accidental or unlawful destruction, loss, alteration, or unauthorized disclosure of, or access to, personal information transmitted, stored, or otherwise processed. You may contact our Data Protection Officer upon becoming aware of any breach of personal information or if your access credentials have been compromised, to enable us to take the necessary steps toward ensuring the security of your personal information or account.

                    <h3>16.	UPDATES TO OUR PRIVACY POLICY</h3>
                    To keep up with legislation and best practices, from time to time, we may change, amend or review this Privacy Policy from time to time and place any updates on this page. All changes made will be posted on this page and where changes will materially affect you, we will notify you of this change by placing a notice online or via mail. If you keep using our Services, you consent to all amendments to this Privacy Policy.
                    Last Updated 5TH day of May 2023

                </div>

            </div>
            <Footer />
        </>
    );
}

export default Privacy;
