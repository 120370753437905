import React from 'react';
import './footer.css';
// import Logo from '..src/Images/logo.svg'
import Newsletter from './Newsletter';
import FooterInc from './FooterInc';
const Footer = () => {
  return (
    <footer className="footer-container">
      <Newsletter />
      <main className="footer">
        <div className="footer-nav-container">
          <div class="footer-nav">
            <h6>Company</h6>

            <ul>
              {/* <li>
                <a href="/about">About Us</a>
              </li> */}
              <li>
                <a href="mailto:onboarding@paysure.ng">Contact Us</a>
              </li>
              {/* <li>
                <a href="/faq">FAQ</a>
              </li> */}
              {/* <li>
                <a href="">Blog</a>
              </li> */}
            </ul>
          </div>

          <div class="footer-nav">
            <h6>Information</h6>

            <ul>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms">Terms & Condition</a>
              </li>
              {/* <li>
                <a href="/legals">Legal Notice</a>
              </li> */}
              <li>
                <a href="mailto:onboarding@paysure.ng">Help</a>
              </li>
            </ul>
          </div>

          <div class="footer-nav">
            <h6>Contact Us</h6>

            <ul>
              <li>
                <a href='/' >Address: No 6 Sani Daura Close, Off Wole Soyinka Avenue Gwarinpa Abuja AMAC FCT</a>
              </li>
              {/* <li>
                <a href="mailto:myPaysure@gmail.com">Mail US</a>
              </li> */}
              {/* <li>
                <a href="+2349025768762">Phone: +234800000000</a>
              </li> */}
            </ul>
          </div>
        </div>
      </main>

      {/* <aside className='download-button-container'>
          <div className='download-btn'>
            <button>
              <iconify-icon
                class="iconify"
                icon="fontisto:google-play"
              ></iconify-icon>
              <span>
                <p>Available on the</p>
                <p>Google Play</p>
              </span>
            </button>
            <button>
            <iconify-icon class='iconify' icon="cib:app-store"></iconify-icon>
              <span>
                <p>Download on the</p>
                <p>Apple Store</p>
              </span>
            </button>
          </div>
          <div className='download-btn web-signin'>
            <button>
              <iconify-icon class="iconify" icon="pepicons:internet"></iconify-icon>
              <p>Sign Up On Web</p>
            </button>
          </div>
      </aside> */}
      <FooterInc />
    </footer>
  );
};

export default Footer;
