import React, { useState } from 'react';
import './dashboard-overview.css';
import userAvatar from '../../../Images/user-avatar.svg';
import wavingHand from '../../../Images/waving-hand.svg';
import moreIcon from '../../../Images/more.svg';
import { useNavigate } from 'react-router-dom';
import FundModal from '../Modals/FundModal.js'

const OverviewBalance = ({ user, links }) => {

  // const [user_data, setUser] = useState(user ? user : {}) , { useState }
  const [fund_modal, setFundModal] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      {fund_modal ? (
        <FundModal setModal={setFundModal} bank={user.account_datails} />
      ) : (
        <>
          <section className="overview-details-container">
            <main>
              <div className="user-overview-details">
                <img className="user-avatar-img" src={userAvatar} alt="" />
                <span>
                  <h3>
                    Welcome back, {user.first_name}! <img src={wavingHand} alt="" />{' '}
                  </h3>
                  <p>Go through your account and explore the features</p>
                </span>
              </div>

              <div className="balance-cards">
                <div className="balance-card">
                  <div className="card-title">
                    <p>Total Balance</p>
                    <img src={moreIcon} alt="" />
                  </div>

                  <div className="card-amount">
                    <h3>NGN{Number(user.balance).toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h3>
                  </div>
                  <div className="balance-card-cta">
                    <button onClick={() => setFundModal(true)}>Fund Wallet</button>

                    <button onClick={() => navigate('/dashboard/withdrawal')} >Withdraw</button>
                  </div>
                </div>

                <div className="balance-card">
                  <div className="card-title">
                    <p>Total Active Links</p>
                    <img src={moreIcon} alt="" />
                  </div>

                  <div className="card-amount">
                    <h3>{links.length}</h3>
                  </div>

                  <div className="balance-card-cta create-payment-link-btn">
                    <button onClick={() => navigate('/dashboard/create-transaction-link')}>Create Payment Link</button>
                  </div>
                </div>
              </div>
            </main>
          </section>
        </>)}
    </>
  );
};

export default OverviewBalance;
