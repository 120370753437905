import React, { useEffect, useState } from 'react';
import './dashboard-overview.css';
import DashboardNavbar from '../../../components/Dashboard Navbar/DashboardNavbar';
import OverviewBalance from './OverviewBalance';
import DashboardRecentTransaction from './DashboardRecentTransaction';
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const DasbhoardContainer = () => {

  const [user, setUser] = useState({})
  const [transactions, setTransaction] = useState([])
  const [links, setLinks] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    let id = localStorage.getItem("id")

    axios.post('/get-user', {
      id: id
    })
      .then(data => {
        setUser(data.data.user)
      })
      .catch(err => {
        console.log(err)
      })

    axios.post('/get-user-transactions', {
      user_id: id
    })
      .then(data => {

        setTransaction(data.data.transactions)
      })
      .catch(err => {
        console.log(err)
      })

    axios.post('/payments/get-user-links', {
      id
    })
      .then(data => {
        setLoading(false)
        setLinks(data.data.link_data)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })

  }, [])

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <section className="dashboard-overview-container">
      <DashboardNavbar name={user.first_name} />
      <OverviewBalance user={user} links={links} />
      <DashboardRecentTransaction transactions={transactions} />
    </section>
  );
};

export default DasbhoardContainer;
