import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const SignInForm = () => {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [secured, setSecured] = useState("password")

  const navigate = useNavigate();



  const login = (ev) => {
    ev.preventDefault()
    setLoading(true)
    let data = {
      userName: email.toLowerCase().trim(),
      password
    }
    axios.post('/login', data)
      .then((res) => {
        if (res.data.message === "success") {
          localStorage.setItem("email", res.data.response.email)
          localStorage.setItem("id", res.data.id)
          localStorage.setItem("token", res.data.token)

          navigate('/dashboard')
        }
        else {
          alert(res.data.message)
        }
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log({
          err
        })
      })
  }

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }


  return (
    <main className="sign-up-form-container">
      <header>
        <h2>Welcome Back!</h2>
        <p>Let’s get you started. Create an account to begin</p>
      </header>

      <form action="">
        <span>
          <label htmlFor="">Email</label>
          <input type="email" placeholder="enter your email or phone" value={email} onChange={(ev) => setEmail(ev.target.value)} />
        </span>

        <span>
          <label htmlFor="">Password</label>
          <input type={secured} placeholder="input password" value={password} onChange={(ev) => setPassword(ev.target.value)} />
        </span>

        <div style={{ display: "flex", alignItems: "center", }}>
          <input style={{ width: 30 }} name='check' type='checkbox' checked={secured !== "password" ? true : false} onChange={() => setSecured(secured === "password" ? "text" : "password")} />
          <label for="check" onClick={() => setSecured(secured === "password" ? "text" : "password")}>Show Password</label>
        </div>

        <p className="forgot-password">Forgot Password?</p>
      </form>

      <div className="form-cta sign-in-form-cta">
        <button type="submit" onClick={(ev) => login(ev)}>Login</button>

        <p>Don't have an account? <Link to='/sign-up'>Sign Up</Link></p>
      </div>
    </main>
  );
};

export default SignInForm;
