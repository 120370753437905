import React from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardRecentTransaction = ({ transactions }) => {

  // const [trxs, setTrxs] = useState(transactions ? transactions : [])

  const navigate = useNavigate()

  return (
    <>
      <div className="recent-transaction-table">
        <header>
          <h4>Recent Transactions</h4>
          <span onClick={() => navigate('/dashboard/transactions')}>
            <p>View All</p>
          </span>
        </header>

        <main></main>
        <table className="recent-transactions">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Time</th>
              <th>Date</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>

          <tbody>
            {transactions && transactions.map((trx) => < tr >
              <td>{trx._id}</td>
              <td>{trx.name}</td>
              <td>&#8358;{trx.amount}</td>
              <td className="status">
                <span>{trx.status}</span>
              </td>
              <td>{new Date(trx.createdAt).getTime()}</td>
              <td>{new Date(trx.createdAt).getDate()}</td>
              {/* <td>1111</td> */}
            </tr>)}

            {/* <tr>
              <td>#11100</td>
              <td>Car Rental</td>
              <td>&#8358;120,000:00</td>
              <td className="status">
                <span>Declined</span>
              </td>
              <td>10:00am</td>
              <td>14th Jan, 2022</td>
              <td>1111</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DashboardRecentTransaction;
