import React, { useState } from 'react'
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const SettingsUserVerification = ({ user }) => {
  const [webhook, setWebhook] = useState(user.webhook ? user.webhook : '');
  const [loading, setLoading] = useState(false);

  const update = (ev) => {
    setLoading(true)
    ev.preventDefault()

    let data = {
      webhook,
      user_id: localStorage.getItem("id")
    }

    axios.post('/update-user', data)
      .then(res => {
        if (res.data.message === "success") {
          setLoading(false)
          alert("Success")
        } else {
          setLoading(false)
          alert(res.data.details)
        }
      })
      .catch(err => {
        setLoading(false)
        alert(err)
        return false
      })

  };

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <main className='settings'>

      <header>
        <h4>API Keys</h4>
        <p>Manage your API integrations here</p>
      </header>

      <form action="">
        <span>
          <label htmlFor="" >Secrete Key</label>
          <input type="text" value={user.secrete_key}
            placeholder='sec_xxxxxxxxxx....' contentEditable={false} />
        </span>

        <span>
          <label htmlFor="">Public Key</label>
          <input type="text" value={user.public_key}
            placeholder='pub_xxxxxxxxxx....' contentEditable={false} />
        </span>

        <span>
          <label htmlFor="">Webhook</label>
          <input type="text" value={webhook}
            onChange={(ev) => setWebhook(ev.target.value)} placeholder='Enter webhook url' />
        </span>
        <button className="save-changes-btn save-btn" onClick={(ev) => update(ev)}>Save Changes</button>
      </form>
    </main>
  )
}

export default SettingsUserVerification