import React, { useState } from 'react';
import './balance-withdrawal.css';
// import WalletAddressIcon from '../../../Images/wallet-address.svg';
import AddWalletAddressIcon from '../../../Images/empty-wallet-add.svg';
import banks from '../../../components/banks';
import { useNavigate } from 'react-router-dom';
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const ChooseAccountDetails = ({ nextWithdrawalStep }) => {

  const [bank_name, setBankName] = useState('');
  const [bank_code, setBankCode] = useState('');
  const [account_number, setAccNum] = useState('');
  const [account_name, setAccName] = useState('');

  const [bb, setBB] = useState({})

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  // const continueWithdrawal = (e) => {
  //   // e.preventDefault();
  //   nextWithdrawalStep();
  // };

  const [selected, setSelected] = useState(null);
  let i = 0;
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const bank = (ev) => {
    let bank_data = JSON.parse(ev);
    setBB(bank_data)
    setBankName(bank_data.Name);
    setBankCode(bank_data.Code);

    if (account_number.length === 10) {
      setLoading(true);

      axios
        .post('/get-account-name', {
          account_number,
          bank_code: bank_data.Code,
        })
        .then((acc) => {
          setLoading(false);
          console.log(acc.data);
          setAccName(acc.data.account_name);
        })
        .catch((err) => {
          setLoading(false);
          alert(err);
        });
    }

    console.log(bank_data.Name);
  };

  const acc_num = (v) => {
    setAccNum(v);

    if (v.length === 10 && bank_name !== '') {
      setLoading(true);
      axios
        .post('/get-account-name', {
          account_number: v,
          bank_code,
        })
        .then((acc) => {
          setLoading(false);
          console.log(acc.data);
          setAccName(acc.data.account_name);
        })
        .catch((err) => {
          setLoading(false);
          alert(err);
        });
    }
  };

  const submit = (ev) => {
    ev.preventDefault();
    setLoading(true);

    let data = {

      bank_code,
      bank_name,
      account_number,
      name: account_name,
      id: localStorage.getItem('id'),

    };

    if (account_name === '' || account_number.length < 10) {
      setLoading(false);
      alert('Please provide correct details');
      return false;
    }

    axios
      .post('/create-recipient', data)
      .then((res) => {
        if (res.data.message === 'success') {
          console.log(res.data.recipient)
          localStorage.setItem("recipient_code", res.data.recipient.recipient_code)
          localStorage.setItem("account_name", account_name)
          localStorage.setItem("bank", bank_name)
          localStorage.setItem("bank_code", bank_code)
          localStorage.setItem("account_number", account_number)
          setLoading(false);
          nextWithdrawalStep();
          // alert('Success');
        } else {
          setLoading(false);
          alert(res.data.details);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
        return false;
      });
  };

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <>
      {/* <div className="steps-filters step-filters-withdrawal">
        <div className="active-step step">
          <p>Bank Account</p>
          <span>01</span>
        </div>
        <div className="step-connector active-connector"></div>
        <div className="active-step step">
          <p>Withdrawal Amount</p>
          <span>02</span>
        </div>

        <div class="step-connector"></div>
        <div className="active-step step">
          <p>Withdrawal Confiration</p>
          <span>03</span>
        </div>
      </div> */}
      <section className="choose-account-details-container">
        <header>
          <h4>Choose Bank Account</h4>
          <p>
            Choose your preferred bank account to proceed with your withdrwal
          </p>
        </header>

        <main className="account-det-options">
          {/* <div className="option-tab option-tab-1">
            <div className="wallet-details">
              <span id="icon">
                <img src={WalletAddressIcon} alt="" />
              </span>

              <span className="wallet-details-main">
                <h6>0477317790 - GTBank</h6>
                <p>Olabode Olakunbi Oluwakayode</p>
              </span>
            </div>

            <input type="radio" name="" id="" />
          </div> */}

          <div className="option-tab option-tab-2 personal-information-settings">
            <div className="wallet-details-container" onClick={() => toggle(i)}>
              <div className="wallet-details">
                <span id="icon">
                  <img src={AddWalletAddressIcon} alt="" />
                </span>

                <span className="wallet-details-main">
                  <h6>Enter a new account details</h6>
                </span>
              </div>

              <input type="radio" name="" id="" />
            </div>

            <form
              className={
                selected === i
                  ? 'wallet-form-content shows-wallet-from'
                  : 'wallet-form-content'
              }
            >

              <div className='sec'>
                <label for="name">Choose Bank</label>
                <select value={JSON.stringify(bb)} onChange={(ev) => bank(ev.target.value)}>
                  <option>Choose Bank</option>
                  {banks.sort((a, b) => {
                    if (a.Name > b.Name) return 1
                    if (b.Name > a.Name) return -1
                    return 0
                  }).map((ban) => (
                    <option value={JSON.stringify(ban)}>{ban.Name}</option>
                  ))}
                </select>
              </div>

              <div className='sec'>
                <label for="email">Account Number</label>
                <input
                  type="text"
                  value={account_number}
                  onChange={(ev) => acc_num(ev.target.value)}
                  placeholder="000000000000"
                />
              </div>

              <div className='sec'>
                <label for="apartment type"> Account Name</label>
                <input type="name" placeholder="Auto fill" value={account_name} contentEditable={false} />
              </div>

            </form>
          </div>
        </main>

        <div className="withdrawal-cta">
          <button onClick={() => navigate('/dashboard')}>Previous</button>
          <button onClick={submit}>Next</button>
        </div>
      </section>
    </>
  );
};

export default ChooseAccountDetails;
