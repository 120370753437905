import React, { useEffect, useState } from 'react';
import DashboardNavbar from '../../../components/Dashboard Navbar/DashboardNavbar'
import TransactionBalance from './TransactionBalance'
import TrasactionList from './TrasactionList'
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const DashboardTransactionContainer = () => {

  const [transactions, setTransaction] = useState([])
  const [inflow, setInflow] = useState()
  const [outflow, setOutFlow] = useState()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})

  useEffect(() => {
    let id = localStorage.getItem("id")
    setLoading(true)

    axios.post('/get-user', {
      id: id
    })
      .then(data => {
        setUser(data.data.user)
      })
      .catch(err => {
        console.log(err)
      })


    axios.post('/get-user-transactions', {
      user_id: id
    })
      .then(data => {
        setTransaction(data.data.transactions)
      })
      .catch(err => {
        console.log(err)
      })

    axios.post('/transaction-flow', {
      user_id: id
    })
      .then(data => {

        setInflow(data.data.inflow)
        setOutFlow(data.data.outflow)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })

  }, [])

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <section className='dashboard-overview-container'>

      <DashboardNavbar />
      <TransactionBalance inflow={inflow} outflow={outflow} user={user} />

      <TrasactionList transactions={transactions} />
    </section>
  )
}

export default DashboardTransactionContainer
