import React from 'react';

import Navbar from '../../components/Navbar/Navbar';
import './heroe-container.css';
// import { Link } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';

function Terms() {
    return (
        <>
        
            <div className="heroe-container">
                <Navbar />

                <div style={{
                    marginTop: 100
                }}>
                    <h1>Introduction</h1>
                    These Website Terms and Conditions (“Terms”) contained herein on this webpage is a legal agreement between you, as a prospective customer of Paysure ’s services and Paysure  Technologies Limited (Paysure , “we”, “our” or “us”) and shall govern your access to and use of Paysure ’s services which include all pages within the Paysure  website, mobile applications and other products and services (collectively referred to as the “Services”).

                    These Terms apply in full force and effect to your use of the Services and by using any of the Services, you expressly accept all terms and conditions contained herein in full and without limitation or qualification, including our Privacy Policy. You must not use any of the Services, if you have any objection to any of these Terms.

                    PLEASE READ AND UNDERSTAND THE TERMS OF AGREEMENT CAREFULLY BEFORE AGREEING TO BE BOUND BY ITS TERMS.

                    <h3>Governing Language</h3>
                    The governing language of these terms and all communication between Paysure and you will be English language.

                    Who May Use Our Services?
                    You may use the Services only if you agree to form a binding contract with Paysure and are not a person barred from receiving services under the laws of the applicable jurisdiction. If you are accepting these Terms and using the Services on behalf of a company, business, or organization, you represent and warrant that you are authorized to do so.

                    <h3>Age Restriction</h3>
                    Our website and services are directed to people from the ages of 18 and above. You are only permitted to use the Services if you are aged 18 or older. We do not knowingly engage people younger than the age of 18.

                    <h3>Intellectual Property</h3>
                    Unless otherwise stated, Paysure  and/or its licensors own the intellectual property rights and materials on the website subject to the license below. All text, formatting (including without limitation the arrangement of materials on the Paysure  website and the graphics, animation, tools, commercials, music, video, articles, sound, copy, trade names, logos and other materials and information on the website are subject to the intellectual property rights of Paysure  and its affiliates and their licensors and licensees (collectively the “Content”). We do not grant you any right, license, title or interest to any of our intellectual Property rights which you may or may not have access to. These Content may not be copied, reverse engineered, decompiled, disassembles, modified or reposted to other websites. Nothing on the Paysure  site should be construed as granting by implication or otherwise, any license or right to use any Trademark displayed on the Paysure  website without the written permission of Paysure  or such third party that may own the Trademark. You agree to take such actions including any legal or official document or other documents that may be needed to further affirm our intellectual property rights.

                    <h3>License to Use Our Website</h3>
                    We grant you a non-assignable, non-exclusive and revocable license to use the software provided as part of our services in the manner permitted by these Terms. This license grant includes all updates, upgrades, new versions and replacement software for you to use in connection with our services.

                    The services are protected by copyright, trademark, and other laws of both Nigeria and foreign countries. Nothing in this Term gives you a right to use the Paysure  name or any of Paysure ’s trademarks, logos, domain names, and other distinctive brand features. All right, title and interest in and to the services are and will remain the exclusive property of Paysure  and its licensors.

                    If you do not comply with all the provisions, then you will be liable for all resulting damages suffered by you, Paysure  and all third parties. Unless otherwise provided by applicable law, you agree not to alter, re-design, reproduce, adapt, display, distribute, translate, disassemble, reverse engineer, or otherwise attempt to create any source code that is derived from the software.

                    Any feedback, comments, or suggestions you may provide to us and our services is entirely voluntary and we will be free to use such feedback, comments or suggestion as we see fit without any obligation to you.

                    Information Security and Warranty Disclaimer
                    PAYSURE WILL ALWAYS ENSURE THAT THE WEBSITE IS AVAILABLE AT ALL TIMES AND BUG FREE. HOWEVER, IT IS USED AT YOUR OWN RISK.

                    WE PROVIDE ALL MATERIALS “AS IS” WITH NO WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND. WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS, INCLUDING ANY IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AVAILABILITY, SECURITY, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, RAVEPAY MAKES NO WARRANTY THAT OUR WEBSITE AND SERVICES WILL MEET YOUR REQUIREMENTS OR THAT OUR WEBSITE WILL REMAIN FREE FROM ANY INTERRUPTION, BUGS, INNACCURACIES, AND ERROR FREE.

                    YOUR USE OF OUR SERVICES ARE AT YOUR OWN RISK AND YOU ALONE WILL BE RESPONSIBLE FOR ANY DAMAGE THAT RESULTS IN LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN OBTAINED BY YOU FROM OUR WEBSITE OR OUR SERVICES WILL CREATE ANY WARRANTY OR CONDITION NOT EXPRESSLY STATED.

                    You are responsible for configuring your information technology, computer programmes and platform in order to access our Services. Please ensure you use your virus protection software or application as we cannot guarantee that our Services will be free from viruses or bugs.

                    You must not attempt to gain unauthorized access to our Services, computers or databases. You must not misuse our Services by introducing trojans, viruses or other materials which are malicious or technologically harmful.

                    <h3>Limitation of Liability</h3>
                    YOUR USE OF PAYSURE  SITE AND SERVICES IS AT YOUR OWN RISK. YOU AGREE TO THE LIMITATION LIABILITY CLAUSE TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW: PAYSURE  WILL IN NO WAY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL PUNITIVE, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES OR ANY DAMAGES INCLUDING DAMAGES RESULTING FROM REVENUE LOSS, PROFIT LOSS, USE, DATA, GOODWILL , BUSINESS INTERRUPTION OR ANY OTHER INTANGIBLE LOSSES (WHETHER PAYSURE  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR NOT) ARISING OUT OF PAYSURE ’S WEBSITE OR SERVICES (INCLUDING, WITHOUT LIMITATION TO INABILITY TO USE, OR ARISING FROM THE RESULT OF USE OF PAYSURE ’S WEBSITE OR SERVICES) WHETHER SUCH DAMAGES ARE BASED ON WARRANTY, TORT, CONTRACT, STATUTE OR ANY OTHER LEGAL THEORY.

                    SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF CERTAIN WARRANTIES OR LIMITATIONS ON THE SCOPE AND DURATION OF SUCH WARRANTIES, SO THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU IN THEIR ENTIRITIES, BUT WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.

                    <h3>Indemnification</h3>
                    You hereby indemnify Paysure  and undertake to keep Paysure , its staff and affiliates indemnified against any losses, damages, costs, liabilities and expenses (including without limitation reasonable legal fees and expenses) arising out of any breach by you of any provision of these Terms, or arising out of any claim that you have breached any provision of these Terms. You will indemnify and hold Paysure  harmless from and against any claim, suit or proceedings brought against Paysure  arising from or in connection with violations of Intellectual Property or other rights of third parties in relation to your use of the Services.

                    <h3>Breach of these Terms</h3>
                    Without prejudice to Paysure ’s other rights under these Terms, if you breach these Terms in any way, Paysure  may take such action as Paysure  deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.

                    <h3>Cookies</h3>
                    Like many other websites, we use cookies to identify you as a user and to customize and improve our services. A cookie is a small data file that is transferred to your computer or mobile device. It enables us to remember your account log-in information, IP addresses, web traffic, number of times you visit, date and time of visits.

                    <h3>Data Protection</h3>
                    Paysure recognizes the importance of protecting the privacy of those who visit and choose to use Paysure Services. Paysure remains compliant with the Payment Card Industry Data Security Standard (PCI/DSS) requirement to the extent applicable. Please note that such requirements may be amended from time to time. With respect to all the Personal Information belonging to, and/or processed in connection with Paysure or this website, such Personal Information at all times is in compliance with all Data Protection Laws in the territory, in all respect and in particular the Nigeria Data Protection Regulation 2019 and all applicable data protection regulation in force. Paysure maintains a Privacy Policy which provides an overview of the Personal Information we collect about you or that you provide to us and how we store and use the information provided by you in line with applicable Data protection legislation. By using the Paysure Services, you consent to such processing and you commit to provide accurate information.

                    <h3>Governing Law</h3>
                    These Terms shall be interpreted and governed in accordance with the Laws of the Federal Republic of Nigeria and you submit to the non-exclusive jurisdiction of the Courts located in Nigeria for the resolution of any dispute.

                    Prohibited and Requiring Pre-Approval Sub-Merchant Types
                    Sub-merchants Requiring Pre-Approval
                    Paysure  shall not solicit or sign agreements with merchants or sub-merchants (i) in any of the following categories/businesses, or (ii) engaging in any of the following activities unless the Paysure  is entering into a broad-based program with a recognized referral, technology or marketplace partner that specializes in these types of activities or businesses and such program is pre-approved by Paysure  in its sole discretion:

                    Age Restricted Products or Services.
                    Any Sub-merchant where the anticipated % of International Card Sales is expected to be greater than 20% of total sales.
                    Bail Bond Services or Bankruptcy Lawyers.
                    Crowd Sourced Fundraising, as defined by Paysure from time to time, except however, Crowd Sourced Fundraising shall not be allowed for the purchase of stock or equity, a promise made to deliver a good or service in the future, or there is no consideration in return for the payment or donation.
                    Dating Services.
                    NGO / Charities.
                    Money Transfers, Wire Transfers, Money Orders, Transmitters, and Check Cashing including merchants unless registered and licensed as a Money Service Business or Money Transfer Operator.
                    Payment Facilitators (unless Registered & Licensed)
                    Personal Enhancement Products and/or Nutraceuticals.
                    Prohibited Sub-Merchants
                    Paysure shall not solicit or sign agreements with merchants or sub-merchants (i) in any of the following categories/businesses, or (ii) engaging in any of the following activities as may be updated by Paysure in its sole discretion from time to time as they are Prohibited by Paysure  and/or the Card Brand rules:

                    Any product, service or activity that is deceptive, unfair, predatory or prohibited by one or more Card Brands.
                    Any Merchant selling goods or services that represent a violation of any law, statute or regulation.
                    Any Merchant selling products that infringe on the intellectual property rights of others, including counterfeit goods or any product or service that infringes on the copyright, trademark or trade secrets of any third party, such as many Cyberlockers.
                    Any Merchant accepting a card as payment for a dishonored check or for an item deemed uncollectible by another merchant.
                    Any Merchant that accepts a card at a scrip-dispensing terminal.
                    Adult entertainment and/or adult content websites including Electronic Commerce adult content (videotext) merchants that would include MCC’s 5967, 7273 and 7841.
                    Bestiality.
                    Brand or Reputational damaging, potential or otherwise, activities including Child Pornography, Escort Services, Mail Order Brides, Occult.
                    Digital Wallet, Cryptocurrency or Prepaid Companies.
                    Drug Paraphernalia.
                    e-Cigarettes.
                    Firearms and weapons including Ammunition.
                    Internet/Mail Order Pharmacies.
                    Investment or “get rich quick” merchants, businesses or programs.
                    Marijuana dispensaries and related products or services.
                    Multi-Level Marketing Businesses.
                    “Negative option” marketing, renewal, or continuity subscription practices; marketing activities involving low-dollar trails, “pay only for shipping,” and/or “free trial” periods after which a credit card is charged periodically and/or a significantly larger amount.
                    Pawn Shops.
                    Pseudo Pharmaceuticals.
                    Psychics and “occult” businesses.
                    Quasi-Cash or Stored Value.
                    Substances designed to mimic illegal drugs.
                    Transacting Virtual Currency or credits that can be monetized, re-sold or converted to physical or digital goods or services or otherwise exit the virtual world.
                    Changes to the Paysure Terms of Use
                    Paysure reserves the right to change, revise or modify these Terms from time to time by updating tthis page The changes will not be retroactive, and the most current version of the Terms which will always be on this page and will continue to govern our relationship with you. We will also try to notify you of any material changes which could be done via email associated with your account or service notification. By continuing to use our services after the changes become effective, you agree to be bound by the revised Terms.


                    <h3>General</h3>
                    These Terms supersedes and extinguishes all previous agreements between you and Paysure whether written or oral, relating to its subject matter. In the event that any of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full effect. Paysure ’s failure to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.

                    <h3>Complaints</h3>
                    If you have any complaints about us and our Services, you may contact us at ______________

                    Contact Us
                    Paysure Technologies Limited
                    Email Address:
                    Address: No 6 Sani Daura Close, Off Wole Soyinka Avenue Gwarinpa Abuja AMAC FCT
                    Hours of Operation: 08:00 – 17:00 WAT (Mon – Fri)
                </div>

            </div>

            <Footer />
        </>
    );
}

export default Terms;
